/* TimerInput.js */
import React, { useState } from 'react';
import './TimerInput.css';
import { toast } from 'react-toastify';
import './TimerInput.css';

const TimerInput = ({ show, onAddTimer, resetTimerSubmit }) => {
  const [inputTime, setInputTime] = useState("00:00");

  const handleAddTimerClick = () => {
    const targetTime = new Date();
    const [hours, minutes] = inputTime.split(':').map(Number);
    targetTime.setHours(hours, minutes, 0, 0); // Set target time to today with the given hours and minutes
  
    const currentTime = new Date();

    // Check if the target time is in the future and not on/after midnight
    if (targetTime <= currentTime || targetTime.getHours() === 0) {
      // Show a toast message
      toast.error("Event may not be in the past!", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000, // 3 seconds duration
        closeButton: false, // No close button
        hideProgressBar: true // Suppress the progress bar
      });
      setInputTime("00:00"); // Reset the input
      return; // Early return to stop the timer from being added
    }

    let difference = targetTime.getTime() - currentTime.getTime();
    difference = difference < 0 ? difference + 86400000 : difference; // Adjust for past time
  
    const totalSeconds = Math.round(difference / 1000);
    onAddTimer(totalSeconds, targetTime); // Pass the target time as the second argument
  };

  if (!show) return null;

  return (
    <div className="timer-input-container">
      <input 
        type="time" 
        value={inputTime}
        onChange={(e) => setInputTime(e.target.value)}
        className="timer-input"
      />
      <button 
        onClick={handleAddTimerClick}
        className="add-timer-button"
      >
        Add Timer
      </button>
    </div>
  );
};

export default TimerInput;
