/* App.js */
import React, { useState, useEffect, useRef } from 'react';
import { getCookieValue } from './utilities';
import { FiSun, FiMoon } from 'react-icons/fi';
import './App.css';
import TimerInput from './TimerInput';
import AddTimerButton from './AddTimerButton';
import CountdownTimer from './CountdownTimer';
import CurrentTimeDisplay from './CurrentTimeDisplay';
import EventTimeDisplay from './EventTimeDisplay';
import { AudioReminder } from './AudioReminder';
import { ToastContainer } from 'react-toastify';
import AudioEnabler from './AudioEnabler';
import 'react-toastify/dist/ReactToastify.css';



function App() {
  const getThemeFromCookie = () => {
    const match = document.cookie.match(/(^| )theme=([^;]+)/);
    return match ? match[2] === 'light' : true; // Default to dark mode
  };

  const savedTheme = getCookieValue('theme');
  const [darkMode, setDarkMode] = useState(savedTheme !== 'light');
  //const [darkMode, setDarkMode] = useState(getThemeFromCookie());
  const [showTimerInput, setShowTimerInput] = useState(false);
  const [eventTimes, setEventTimes] = useState([]);
  const [nextEventTime, setNextEventTime] = useState(null);
  const audioContextRef = useRef(new (window.AudioContext || window.webkitAudioContext)());
  const [audioEnabled, setAudioEnabled] = useState(false);
  const [timerStarted, setTimerStarted] = useState(false);
  const [volume, setVolume] = useState(1.0); // Initial volume set to 100%


  useEffect(() => {
    if (eventTimes.length > 0) {
      const upcomingEvent = eventTimes[0];
      updateNextEventTime(upcomingEvent);

      // Pass audioContextRef.current and audioEnabled state to the AudioReminder instance
      const audioReminder = new AudioReminder(upcomingEvent, audioContextRef.current, audioEnabled, volume);
      audioReminder.setupReminders();

      // Set up an interval to check for past events
      const intervalId = setInterval(() => {
        const now = new Date();
        if (now >= upcomingEvent) {
          // The upcomingEvent time has passed, remove it from the list
          setEventTimes(prevEventTimes => prevEventTimes.filter((eventTime, index) => index !== 0));
        }
      }, 1000); // Check every second

      // Cleanup routine for both audio reminders and the interval
      return () => {
        audioReminder.cleanup();
        clearInterval(intervalId); // Clear the interval when the component unmounts or eventTimes changes
      };
    }
  }, [eventTimes, audioEnabled, volume, setEventTimes]); // Include setEventTimes to the dependency array

  const calculateTimeUntil = (eventDateTime) => {
    const now = new Date();
    const timeUntilEvent = eventDateTime.getTime() - now.getTime();
    return Math.max(0, Math.floor(timeUntilEvent / 1000)); // Ensure non-negative
  };

  const updateNextEventTime = (eventDateTime) => {
    const timeUntilEvent = calculateTimeUntil(eventDateTime);
    setNextEventTime(timeUntilEvent);
  };

  const handleAddTimerClick = (totalSeconds, eventDateTime) => {
    const newEventTimes = [...eventTimes, eventDateTime].sort(
      (a, b) => a.getTime() - b.getTime()
    );
    setEventTimes(newEventTimes);
    setShowTimerInput(false);
  };

  const toggleTheme = () => {
    const newDarkMode = !darkMode;
    setDarkMode(newDarkMode);
    document.cookie = `theme=${newDarkMode ? 'dark' : 'light'}; path=/`;
  };

  const removeEventTime = (indexToRemove) => {
    setEventTimes(prevEventTimes =>
      prevEventTimes.filter((_, index) => index !== indexToRemove)
    );
  };

  // Callback function to pass to AudioEnabler
  const handleAudioToggle = (isEnabled) => {
    setAudioEnabled(isEnabled);
  };


  return (
    <div className={`App ${darkMode ? '' : 'light-mode'}`}>
      <ToastContainer />
      <AudioEnabler audioContext={audioContextRef.current} onAudioToggle={handleAudioToggle} />
      <header className="App-header">
        <CurrentTimeDisplay />

        {/* Render all event times, stacked at the center bottom */}
        {eventTimes.slice().reverse().map((eventTime, index) => {
          const originalIndex = eventTimes.length - 1 - index;
          return (
            <EventTimeDisplay
              key={originalIndex}
              eventTime={eventTime}
              onRemove={() => removeEventTime(originalIndex)} // Pass the handler down to EventTimeDisplay
              style={{
                top: `${window.innerHeight / 2 + 80 + (originalIndex * 50)}px`,
                left: '50%',
                transform: 'translateX(-50%)',
              }}
            />
          );
        })}

        {eventTimes.length > 0 && (
          <CountdownTimer eventTime={eventTimes[0]} />
        )}

        {showTimerInput && (
          <div className="screen-cover" onClick={() => setShowTimerInput(false)} />
        )}

        <TimerInput show={showTimerInput} onAddTimer={handleAddTimerClick} />
        <AddTimerButton onClick={() => setShowTimerInput(true)} />
        <button onClick={toggleTheme} className="theme-toggle-btn">
          {darkMode ? <FiSun /> : <FiMoon />}
        </button>

      </header>
    </div>
  );
}

export default App;