/* AddTimerButton.js */
import React from 'react';
import { FaPlus } from 'react-icons/fa';
import './AddTimerButton.css'; // Import the new CSS styles

const AddTimerButton = ({ onClick }) => {
  return (
    <button 
      className="add-timer-action-button"
      onClick={onClick}
    >
      <FaPlus className="add-timer-button-icon" />
    </button>
  );
};

export default AddTimerButton;