// AudioReminder.js
export class AudioReminder {
    constructor(eventTime, audioContext, audioEnabled) {
        this.eventTime = eventTime;
        this.playedReminders = {}; // Object to keep track of which reminders have been played
        this.audioEnabled = audioEnabled;
        this.audioContext = audioContext;
        this.bufferCache = {};
    }

    setupReminders() {
        this.reminderTimes = {
            // Paths to audio files are unchanged
            '3603': '/EventStarting60.wav', // 60 minutes before the event
            '1803': '/EventStarting30.wav',
            '903': '/EventStarting15.wav',
            '603': '/EventStarting10.wav',
            '303': '/EventStarting5.wav',
            '63': '/EventStarting1.wav',
            '8': '/EventStarting.wav',
            // ...other reminder times
        };
        this.checkAndPlayReminder();
        this.intervalId = setInterval(() => this.checkAndPlayReminder(), 1000);
    }

    checkAndPlayReminder() {
        const now = new Date();
        const secondsUntilEvent = (this.eventTime.getTime() - now.getTime()) / 1000;

        Object.keys(this.reminderTimes).forEach(time => {
            if (Math.ceil(secondsUntilEvent) === parseInt(time) && !this.playedReminders[time]) {
                this.playAudio(this.reminderTimes[time]);
                this.playedReminders[time] = true; // Mark as played
            }
        });
    }

    playAudio(audioPath) {
        if (!this.audioEnabled) return;
        // Check if the buffer is already loaded
        if (this.bufferCache[audioPath]) {
            this.startPlayback(audioPath, this.bufferCache[audioPath]);
        } else {
            // Fetch and decode the audio file
            fetch(audioPath)
                .then(response => response.arrayBuffer())
                .then(arrayBuffer => this.audioContext.decodeAudioData(arrayBuffer))
                .then(audioBuffer => {
                    this.bufferCache[audioPath] = audioBuffer; // Cache it to avoid re-fetching
                    this.startPlayback(audioPath, audioBuffer);
                })
                .catch(e => console.error("Audio Playback Error:", e));
        }
    }

    startPlayback(audioPath, audioBuffer) {
        const source = this.audioContext.createBufferSource();
        source.buffer = audioBuffer;
        source.connect(this.audioContext.destination);
        source.start(0); // Play immediately
    }

    cleanup() {
        clearInterval(this.intervalId);
        //this.audioContext.close(); // Close the AudioContext when no longer needed
    }
}