// EventTimeDisplay.js
import React from 'react';
import { FaTimes } from 'react-icons/fa';
import './EventTimeDisplay.css'; // Ensure this is the correct path to your CSS file

const EventTimeDisplay = ({ eventTime, style, onRemove }) => { // Make sure to include onRemove here
  // Formatting options for hours and minutes only
  const timeOptions = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true // This can be set to false for a 24-hour format
  };

  const formattedEventTime = eventTime.toLocaleTimeString([], timeOptions);

  return (
    <div className="event-time-display" id="event-time-display-elem" style={style}>
      {formattedEventTime}
      <button onClick={onRemove} className="remove-event-button">
        <FaTimes /> {/* Place the icon inside the button */}
      </button> {/* Add button to remove this event */}
    </div>
  );
};

export default EventTimeDisplay;