// CountdownTimer.js
import React, { useState, useEffect } from 'react';
import './CountdownTimer.css'; // Make sure this is the path to your CSS file

const CountdownTimer = ({ eventTime }) => {
  const [time, setTime] = useState(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date();
      const secondsUntilEvent = (eventTime.getTime() - now.getTime()) / 1000;
      setTime(secondsUntilEvent > 0 ? Math.ceil(secondsUntilEvent) : 0);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [eventTime]);

  const formatTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    const paddedHours = hours.toString().padStart(2, '0');
    const paddedMinutes = minutes.toString().padStart(2, '0');
    const paddedSeconds = seconds.toString().padStart(2, '0');

    return hours > 0 ? `${paddedHours}:${paddedMinutes}:${paddedSeconds}` : `${paddedMinutes}:${paddedSeconds}`;
  };

  return (
    <div className="countdown-timer">
      {formatTime(time)}
    </div>
  );
};

export default CountdownTimer;