// CurrentTimeDisplay.js
import React, { useState, useEffect } from 'react';
import './CurrentTimeDisplay.css'; // Ensure this is the path to your CSS file

const CurrentTimeDisplay = () => {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  // Format for hours and minutes only
  const timeOptions = {
    hour: '2-digit',
    minute: '2-digit',
  };

  return (
    <div className="time-display">
      {currentTime.toLocaleTimeString([], timeOptions)}
    </div>
  );
};

export default CurrentTimeDisplay;