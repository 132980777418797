// client/src/AudioEnabler.js
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { FiVolume2, FiVolumeX } from 'react-icons/fi';
import './AudioEnabler.css';

export const AudioEnabler = ({ audioContext, onAudioToggle, darkMode }) => {
    const [audioEnabled, setAudioEnabled] = useState(false);
    const audioBufferRef = useRef(null);

    // Function to initialize and store the audio buffer
    const initAudio = useCallback(async () => {
        try {
            const response = await fetch('/EventStarting1.wav');
            const arrayBuffer = await response.arrayBuffer();
            audioBufferRef.current = await audioContext.decodeAudioData(arrayBuffer);
        } catch (e) {
            console.error("Audio Fetching Error:", e);
        }
    }, [audioContext]);

    // Toggle audioEnabled state and execute a provided callback function
    const toggleAudio = useCallback(() => {
        const newState = !audioEnabled;
        setAudioEnabled(newState);

        // Run the callback function when audio is enabled
        onAudioToggle(newState);

        // Play audio immediately if the user is enabling sound
        if (newState && audioBufferRef.current) {
            const source = audioContext.createBufferSource();
            source.buffer = audioBufferRef.current;
            source.connect(audioContext.destination);
            source.start();
        }
    }, [audioEnabled, audioContext, onAudioToggle]);

    // Initialize the audio buffer once when component mounts
    useEffect(() => {
        initAudio();
    }, [initAudio]);

    return (
        <button onClick={toggleAudio} className={`audio-enable-button ${darkMode ? 'dark' : 'light'}`}>
            {audioEnabled ? <FiVolumeX /> : <FiVolume2 />}
        </button>
    );
};

export default AudioEnabler;